import { API_URL, SERVICES_SUBSCRIPTION_V2_URL } from "../../config";

import { getAuthWithAppName } from "../auth";

import cuid from "cuid";
import uuidV4 from "uuid/v4";

import thrift from "browser-thrift";

import ServicesSubscriptionV2Config from "../../assets/thrift/service-subscription/v2/ServicesSubscription";
import ServicesSubscriptionV2Types from "../../assets/thrift/service-subscription/v2/services-subscription_types";

import { postRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";

const serviceSubscriptionV2ClientUrl = new URL(SERVICES_SUBSCRIPTION_V2_URL);
const serviceSubscriptionV2Client = thrift.createXHRClient(
    ServicesSubscriptionV2Config,
    thrift.createXHRConnection(serviceSubscriptionV2ClientUrl.hostname, 443, {
        useCors: true,
        path: serviceSubscriptionV2ClientUrl.pathname,
        https: serviceSubscriptionV2ClientUrl.protocol === "https:"
    })
);

export const FETCH_ACTIVE_SERVICE_START = "FETCH_ACTIVE_SERVICE_START";
export const FETCH_ACTIVE_SERVICE_ERROR = "FETCH_ACTIVE_SERVICE_ERROR";
export const FETCH_ACTIVE_SERVICE_SUCCESS = "FETCH_ACTIVE_SERVICE_SUCCESS";
export const FETCH_ACTIVE_SERVICE_RESET = "FETCH_ACTIVE_SERVICE_RESET";

export function fetchActiveServices(itemId) {
    return async (dispatch, getState) => {
        dispatch({ type: FETCH_ACTIVE_SERVICE_START, payload: { itemId } });

        const request = new ServicesSubscriptionV2Types.ItemServicesRequest({
            itemIds: [itemId]
        });

        const auth = getAuthWithAppName(getState().auth);
        const header = new ServicesSubscriptionV2Types.Header({
            accessToken: auth.securityToken,
            appName: auth.appName,
            requestId: cuid(),
            correlationId: uuidV4()
        });

        const ItemServicesResponse = await serviceSubscriptionV2Client.itemServices(header, request);

        dispatch({
            type: FETCH_ACTIVE_SERVICE_SUCCESS,
            payload: { itemId, services: ItemServicesResponse.itemServices[itemId] }
        });
    };
}

export const resetActiveServices = () => dispatch => dispatch({ type: FETCH_ACTIVE_SERVICE_RESET });

export const ACTIVATE_SERVICE_START = "ACTIVATE_SERVICE_START";
export const ACTIVATE_SERVICE_ERROR = "ACTIVATE_SERVICE_ERROR";
export const ACTIVATE_SERVICE_SUCCESS = "ACTIVATE_SERVICE_SUCCESS";
export const ACTIVATE_SERVICE_RESET = "ACTIVATE_SERVICE_RESET";

/**
 * Attivazione generica di un servizio
 * @param {Auth} auth
 * @param {string} itemId
 * @param {string} serviceName
 * @param {object} extraData
 * @returns {function(*=)}
 */
export function activateService(itemId, serviceId, extraData) {
    return (dispatch, getState) => {
        return new Promise(async (resolve, reject) => {
            dispatch({
                type: ACTIVATE_SERVICE_START,
                payload: {
                    itemId,
                    ownerId: itemId,
                    serviceId,
                    extraData
                }
            });

            const request = {
                id: itemId,
                ownerId: itemId,
                serviceId,
                extraData
            };

            try {
                const auth = getAuthWithAppName(getState().auth);
                await postRestCall(
                    `${API_URL}/service-subscription`,
                    auth,
                    {},
                    request,
                    dispatch,
                    auth.refreshToken,
                    getUserProfile(getState()),
                    itemId,
                    true,
                    true,
                    true
                );

                dispatch({
                    type: ACTIVATE_SERVICE_SUCCESS,
                    payload: {
                        serviceId,
                        extraData
                    }
                });
                resolve();
            } catch (e) {
                dispatch({
                    type: ACTIVATE_SERVICE_ERROR,
                    payload: {
                        serviceId,
                        extraData
                    },
                    error: e
                });
                reject();
            }
        });
    };
}

export function activateServiceReset() {
    return dispatch => {
        dispatch({
            type: ACTIVATE_SERVICE_RESET
        });
    };
}

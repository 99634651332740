import React, { Component } from "react";

import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";

import { createApiKey } from "../../actions/company/users";
import { findOwnManagedConnections } from "../../actions/connections/read";
import { listItems } from "../../actions/registry/read";
import { fetchItemServices } from "../../actions/services/service-subscription-v2";
import { AccordionStepper } from "../../components/general/accordion-stepper";
import { AccordionStep } from "../../components/general/accordion-stepper/accordion-step";
import { errorBoundary } from "../../components/general/error-boundary";
import CreateApiKey from "../../components/manage-data/user/new-user-modal/create-api-key";
import SelectCompanies from "../../components/manage-data/user/new-user-modal/select-companies";
import SelectRoles from "../../components/manage-data/user/new-user-modal/select-roles";
import { parseRoles } from "../../utils/get-roles";
import { Container } from "./styled";

class CreateApiKeyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            availableItemsPage: 1,
            existingUser: false,
            existingUserModalOpen: false,
            apiKey: {},
            filter: "",
            selectedItems: [props.selectedItemId],
            step: 0
        };
    }

    componentDidUpdate(prevProps) {
        const { createUserStatus, connectionsLoading, listItems, history, itemServicesLoading, match, selectedItemId } =
            this.props;
        const { step } = this.state;

        if (prevProps.connectionsLoading && !connectionsLoading) {
            this.setState({ step: step + 1 });

            const itemsToLoad = this.getAvailableItemsPage(1);
            // The selected item is always preselected, so its data must be loaded
            itemsToLoad.push(selectedItemId);

            listItems(itemsToLoad);
        }

        if (prevProps.itemServicesLoading && !itemServicesLoading) {
            this.setState({ step: step + 1 });
        }

        if (prevProps.createUserStatus.started && createUserStatus.ended) {
            history.push(`/${match.params.item}/registry/apikey/create/success`);
        }
    }

    getAvailableItemsPage = pageNumber => {
        const { visibleItems } = this.props;
        const { filter } = this.state;

        return visibleItems
            .filter(
                i =>
                    i.id.toLowerCase().includes(filter.toLocaleLowerCase()) ||
                    i.description.toLowerCase().includes(filter.toLocaleLowerCase())
            )
            .slice((pageNumber - 1) * 5, pageNumber * 5)
            .map(i => i.id);
    };

    getTotalItems = () => {
        const { visibleItems } = this.props;
        const { filter } = this.state;

        return visibleItems.filter(
            i =>
                i.id.toLowerCase().includes(filter.toLocaleLowerCase()) ||
                i.description.toLowerCase().includes(filter.toLocaleLowerCase())
        ).length;
    };

    handleCompanySearch = (value, page, size = 5) => {
        const { listItems } = this.props;

        const items = this.getAvailableItemsPage(page + 1);

        listItems(items);
        this.setState({ filter: value, availableItemsPage: page + 1 });
    };

    handleSelectAll = () => {
        const { listItems, visibleItems } = this.props;

        this.setState({ selectedItems: visibleItems.map(i => i.id) });
        listItems(visibleItems.map(i => i.id));
    };

    handleUserCreation = roles => {
        const { createApiKey, userRoles } = this.props;
        const { apiKey } = this.state;

        const rolesToAdd = parseRoles(roles, userRoles);

        createApiKey(apiKey, rolesToAdd);
    };

    handleUserData = userData => {
        const { findOwnManagedConnections, selectedItemId } = this.props;

        this.setState({
            apiKey: {
                ...userData
            }
        });

        findOwnManagedConnections(
            { active: true, deleted: false, managerIds: selectedItemId },
            { page: 0, size: 2000 }
        );
    };

    render() {
        const {
            agyoServices,
            companies,
            connectionsLoading,
            createUserStatus,
            fetchItemServices,
            itemServices,
            itemServicesLoading,
            listItemsLoading,
            match,
            selectedItemId,
            userRoles
        } = this.props;
        const { availableItemsPage, selectedItems, step } = this.state;

        return (
            <Container>
                <AccordionStepper
                    backRedirectUrl={`/${match.params.item}/registry/apikey`}
                    currentStep={step}
                    displayEndButtons={step !== 2}
                >
                    <AccordionStep
                        title={<FormattedMessage id="c-manage-data.multicompany-create.create-tech-user" />}
                        subtitle={
                            <FormattedMessage id="c-manage-data.multicompany-create.create-tech-user-description" />
                        }
                    >
                        <CreateApiKey loading={connectionsLoading} onSubmit={this.handleUserData} />
                    </AccordionStep>
                    <AccordionStep
                        title={<FormattedMessage id="c-manage-data.multicompany-create.select-companies" />}
                        subtitle={
                            <FormattedMessage id="c-manage-data.multicompany-create.select-companies-description" />
                        }
                    >
                        <SelectCompanies
                            companies={companies}
                            companiesPage={this.getAvailableItemsPage(availableItemsPage)}
                            initialSelectedItems={[selectedItemId]}
                            loadingItems={listItemsLoading}
                            loadingNext={itemServicesLoading}
                            onBack={() =>
                                this.setState({
                                    userExists: false,
                                    step: step - 1
                                })
                            }
                            onSearch={this.handleCompanySearch}
                            onSelectAll={this.handleSelectAll}
                            onSubmit={items => {
                                fetchItemServices(items.map(i => i.id));
                                this.setState({
                                    selectedItems: items
                                });
                            }}
                            totalItems={this.getTotalItems()}
                            userRoles={userRoles}
                        />
                    </AccordionStep>
                    <AccordionStep
                        title={<FormattedMessage id="c-manage-data.multicompany-create.select-roles" />}
                        subtitle={<FormattedMessage id="c-manage-data.multicompany-create.select-roles-description" />}
                    >
                        <SelectRoles
                            agyoServices={agyoServices}
                            backDisabled={false}
                            backUrl={`/${match.params.item}/registry/user`}
                            customRoles={{}}
                            endLoading={createUserStatus.started}
                            forwardDisabled={false}
                            itemServices={itemServices}
                            onBack={() => this.setState({ step: step - 1 })}
                            onSelectRoles={this.handleUserCreation}
                            selectedCompanies={selectedItems}
                            submissionError={createUserStatus.errorInfo.message}
                            userRoles={userRoles}
                        />
                    </AccordionStep>
                </AccordionStepper>
            </Container>
        );
    }
}

const actions = {
    createApiKey,
    findOwnManagedConnections,
    fetchItemServices,
    listItems
};

const mapStateToProps = (state, props) => {
    const selectedItem = state.companies.data[props.match.params.item]
        ? state.companies.data[props.match.params.item].item
        : {};

    const visibleItems = state.connectionsNew.read.findOwnManagedConnections.content.map(link => ({
        id: link.managedId,
        description: link.managedDescription
    }));
    visibleItems.push({
        id: selectedItem.base.id,
        description: selectedItem.base.details.description
    });

    return {
        agyoServices: state.services.agyoServices,
        companies: Object.values(state.companies.totalRoleAdd.items)
            .map(i => i.item.base)
            .filter(i => visibleItems.map(visibleItem => visibleItem.id).includes(i.id)),
        companiesLoading: state.companies.totalRoleAdd.status.started,
        connectionsLoading: state.connectionsNew.read.findOwnManagedConnections.status.started,
        createUserStatus: state.manageUser.createUser.status,
        currentUserId: state.user.user && state.user.user.profile.id,
        itemServices: state.services.itemServices.services,
        itemServicesLoading: state.services.itemServices.status.started,
        listItemsLoading: state.registry.read.listItems.status.started,
        selectedItemId: selectedItem.base.id,
        userRoles: state.user.user ? state.user.user.roles : [],
        visibleItems: visibleItems
    };
};

const composedHoc = compose(errorBoundary, connect(mapStateToProps, actions));

export default composedHoc(CreateApiKeyView);

import { flatten } from "lodash";
import { API_URL, SERVICE_SUBSCRIPTION_V2_URL } from "../../config";
import { getRestCall } from "../../utils/rest-api-call";
import { getUserProfile } from "../../utils/state-utils/get-user-profile";
import { getAuthWithAppName } from "../auth";

export const FETCH_ITEM_SERVICES_START = "FETCH_ITEM_SERVICES_START";
export const FETCH_ITEM_SERVICES_ERROR = "FETCH_ITEM_SERVICES_ERROR";
export const FETCH_ITEM_SERVICES_SUCCESS = "FETCH_ITEM_SERVICES_SUCCESS";
export const FETCH_ITEM_SERVICES_RESET = "FETCH_ITEM_SERVICES_RESET";

export function fetchItemServices(...itemIds) {
    return async (dispatch, getState) => {
        dispatch({
            type: FETCH_ITEM_SERVICES_START,
            payload: { itemIds }
        });

        const auth = getAuthWithAppName(getState().auth);
        const url = `${API_URL}/services`;

        try {
            const result = await getRestCall(
                url,
                auth,
                { items: flatten(itemIds) },
                dispatch,
                auth.refreshToken,
                getUserProfile(getState())
            );

            const itemsWithoutServices = itemIds
                .flat(1) // ensures we are always working with a flat array
                .filter(id => !Object.keys(result).includes(id))
                .reduce((acc, id) => {
                    acc[id] = [];
                    return acc;
                }, {});

            dispatch({
                type: FETCH_ITEM_SERVICES_SUCCESS,
                payload: {
                    itemIds,
                    result: {
                        itemServices: {
                            ...result,
                            ...itemsWithoutServices
                        }
                    }
                }
            });
        } catch (e) {
            dispatch({
                type: FETCH_ITEM_SERVICES_ERROR,
                payload: { error: e }
            });
        }
    };
}
export function resetFetchItemServices(auth) {
    return async dispatch => {
        dispatch({
            type: FETCH_ITEM_SERVICES_RESET
        });
    };
}

export const FETCH_AGYO_SERVICES_START = "FETCH_AGYO_SERVICES_START";
export const FETCH_AGYO_SERVICES_ERROR = "FETCH_AGYO_SERVICES_ERROR";
export const FETCH_AGYO_SERVICES_SUCCESS = "FETCH_AGYO_SERVICES_SUCCESS";

export function fetchAgyoServices() {
    return async (dispatch, getState) => {
        dispatch({
            type: FETCH_AGYO_SERVICES_START
        });

        const url = `${SERVICE_SUBSCRIPTION_V2_URL}/services`;

        try {
            const auth = getAuthWithAppName(getState().auth);
            const result = await getRestCall(url, auth, {}, dispatch, auth.refreshToken, getUserProfile(getState()));

            dispatch({
                type: FETCH_AGYO_SERVICES_SUCCESS,
                payload: result.agyoServices
            });
        } catch (e) {
            dispatch({
                type: FETCH_AGYO_SERVICES_ERROR,
                payload: { error: e }
            });
        }
    };
}

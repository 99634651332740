import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router";
import { Link } from "react-router-dom";

import {
    CCT_CONSOLE,
    BILANCIO_SAAS,
    TS_PAY_URL,
    TS_HOSPITALITY_URL,
    HR_URL,
    COMMON_LOGIN_API_URL,
    ARCHIVE_WEBAPP_URL
} from "../../../config";
import { getCurrentCookieConsentsCsv } from "../../../utils/cookie-consent-utils";
import { useFeature } from "../../../hooks/use-feature";

const appLinks = {
    ARC: { isExternal: true, link: CCT_CONSOLE },
    AML: { link: "/aml" },
    ANA: { link: "/analytics" },
    DBX: { link: "/digitalbox" },
    DRAG: { link: "/drag" },
    EIP: { link: "/fatturazione" },
    "GDPR-LOG": { link: "/gdprlog" },
    PECMANAGER: { link: "/pecmanager" },
    PPL: { link: "/people-app" },
    PRIVACY: { link: "/privacy" },
    SIG: { link: "/firma" },
    TEL: { link: "/adempimenti" },
    BDG: { isExternal: true, link: BILANCIO_SAAS, sendToken: true, cookieConsents: true },
    EIT: { link: "/invoice-trading" },
    ESM: { link: "/spendmanagement" },
    CRAM: { link: "/cram-front" },
    AGRO: { link: "/agro" },
    NIUMABONUS: { link: "/niumabonus" },
    EBS: { isExternal: true, link: TS_PAY_URL },
    SPID: { link: "/spid" },
    BIS: { link: "/business-information" },
    DDL: { link: "/deadlines" },
    TSH: { isExternal: true, link: TS_HOSPITALITY_URL },
    HR: { isExternal: true, link: HR_URL },
    SCF: { link: "/deeptier" },
    CYBER: { link: "/cyber" },
    BI: { link: "/business-intelligence" }
};

export const AppLink = ({ children, companyStatus, serviceId, services, token }) => {
    const params = useParams();
    const newBilancioFeature = useFeature("NEW_BILANCIO");

    let link = {};

    if (serviceId === "ARC" && services.find(x => x.id === "ARC" && x.activeForItem)) {
        link = { isExternal: true, link: ARCHIVE_WEBAPP_URL };
    } else if (appLinks[serviceId]) {
        link = appLinks[serviceId];
    } else {
        link = {};
    }

    if (link.isExternal) {
        let extLink;
        const cookieCsv = getCurrentCookieConsentsCsv();

        if (serviceId === "BDG" && newBilancioFeature) {
            extLink = `${COMMON_LOGIN_API_URL}/jump?serviceId=pro:bilancio:bilancio&deepLink=?cookieConsents=${encodeURIComponent(
                cookieCsv
            )}`;
        } else if (link.cookieConsents) {
            extLink = `${link.link}?securityToken=${token}&cookieConsents=${encodeURIComponent(cookieCsv)}`;
        } else {
            extLink = link.sendToken ? `${link.link}?securityToken=${token}` : link.link;
        }

        return (
            <a
                href={extLink}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                    // disabled click when companyStatus is not CERTIFIED for SPID app. temporary fix.
                    pointerEvents: companyStatus !== "CERTIFIED" && serviceId === "SPID" && "none"
                }}
            >
                {children}
            </a>
        );
    } else {
        return (
            <Link
                to={`/${params.item}/apps${link.link}`}
                style={{
                    // disabled click when companyStatus is not CERTIFIED for SPID app. temporary fix.
                    pointerEvents: companyStatus !== "CERTIFIED" && serviceId === "SPID" && "none"
                }}
            >
                {children}
            </Link>
        );
    }
};

AppLink.propTypes = {
    children: PropTypes.any,
    companyStatus: PropTypes.string,
    serviceId: PropTypes.string.isRequired,
    services: PropTypes.array,
    token: PropTypes.string
};

export default AppLink;
